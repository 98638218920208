<template>
  <div class="app-customizer">
    <v-btn
      icon
      class="app-customizer-toggler rounded-0"
      :class="$vuetify.rtl ? 'rounded-r-lg' : 'rounded-l-lg'"
      color="white"
      large
      @click="isCustomizerOpen = !isCustomizerOpen"
    >
      <v-icon size="25">
        {{ icons.mdiFilterMenu }}
      </v-icon>
    </v-btn>
    <v-navigation-drawer
      v-model="isCustomizerOpen"
      :right="!$vuetify.rtl"
      temporary
      fixed
      width="400"
      class="app-customizer-drawer"
    >
      <div class="app-customizer-header customizer-section py-3">
        <span :style="`color: ${$vuetify.theme.themes.light.primary};font-size: 18px`">
          <b>{{ $t('lbl.filtros') }} </b>
          <span
            :style="`color: ${$vuetify.theme.themes.light.primary}`"
          >({{ countFilters }})</span>
        </span><br />
        <span>
          <span :style="`color: ${$vuetify.theme.themes.light.primary}`">
            <b>{{ cotizadorHotelsResultPaginate.length }}</b>
          </span>
          {{ $t('lbl.hotelsFilters') }}
          <span :style="`color: ${$vuetify.theme.themes.light.primary}`">
            <b>{{ cotizadorHotelsAll }}</b>
          </span>
          <span
            class="ml-5"
            :style="`color: ${$vuetify.theme.themes.light.primary};text-decoration: underline;cursor: pointer`"
            @click="clearCotizadorHotelsResultFilters"
          >{{ $t('lbl.clearFilters') }}</span>
        </span>
        <v-btn
          icon
          class="icon-customizer-close"
          @click="isCustomizerOpen = false"
        >
          <v-icon>
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <v-divider></v-divider>

      <perfect-scrollbar
        :options="perfectScrollbarOptions"
        class="ps-customizer"
      >
        <!--NOMBRE-->
        <div class="customizer-section">
          <span class="text--primary">{{ $t('lbl.nameAlojamientoFilters') }}</span>
          <v-text-field
            v-if="!loadingCategories"
            v-model="cotizadorHotelsResultFilters.name"
            :label="$t('lbl.nameAlojamientoFilters')"
            outlined
            dense
            single-line
            :append-icon="icons.mdiMagnify"
            @input="filtersCotizadorHotelsResult"
          >
          </v-text-field>
          <v-skeleton-loader
            v-else
            class="mx-auto"
            type="card-heading"
          ></v-skeleton-loader>
        </div>

        <v-divider></v-divider>

        <!--CATEGORIA-->
        <div class="customizer-section">
          <span class="text--primary">{{ $t('lbl.categoryFilters') }}</span>
          <template v-if="!loadingCategories">
            <v-checkbox
              v-if="start5.id"
              v-model="cotizadorHotelsResultFilters.categories"
              :value="start5.id"
              hide-details
              @change="filtersCotizadorHotelsResult"
            >
              <template #label>
                <v-row>
                  <v-col cols="10">
                    <v-rating
                      background-color="default"
                      color="orange"
                      :empty-icon="icons.mdiStarOutline"
                      :full-icon="icons.mdiStar"
                      :half-icon="icons.mdiStarHalfFull"
                      length="5"
                      readonly
                      size="20"
                      dense
                      half-increments
                      :value="start5.cant_estrellas"
                    ></v-rating>
                  </v-col>
                  <v-col cols="2">
                    ({{ countStar(start5.id) }})
                  </v-col>
                </v-row>
              </template>
            </v-checkbox>
            <v-checkbox
              v-if="start4.id"
              v-model="cotizadorHotelsResultFilters.categories"
              :value="start4.id"
              hide-details
              @change="filtersCotizadorHotelsResult"
            >
              <template #label>
                <v-row>
                  <v-col cols="10">
                    <v-rating
                      background-color="default"
                      color="orange"
                      :empty-icon="icons.mdiStarOutline"
                      :full-icon="icons.mdiStar"
                      :half-icon="icons.mdiStarHalfFull"
                      length="5"
                      readonly
                      size="20"
                      dense
                      half-increments
                      :value="start4.cant_estrellas"
                    ></v-rating>
                  </v-col>
                  <v-col cols="2">
                    ({{ countStar(start4.id) }})
                  </v-col>
                </v-row>
              </template>
            </v-checkbox>
            <v-checkbox
              v-if="start3.id"
              v-model="cotizadorHotelsResultFilters.categories"
              :value="start3.id"
              hide-details
              @change="filtersCotizadorHotelsResult"
            >
              <template #label>
                <v-row>
                  <v-col cols="10">
                    <v-rating
                      background-color="default"
                      color="orange"
                      :empty-icon="icons.mdiStarOutline"
                      :full-icon="icons.mdiStar"
                      :half-icon="icons.mdiStarHalfFull"
                      length="5"
                      readonly
                      size="20"
                      dense
                      half-increments
                      :value="start3.cant_estrellas"
                    ></v-rating>
                  </v-col>
                  <v-col cols="2">
                    ({{ countStar(start3.id) }})
                  </v-col>
                </v-row>
              </template>
            </v-checkbox>
            <v-checkbox
              v-if="start2.id"
              v-model="cotizadorHotelsResultFilters.categories"
              :value="start2.id"
              hide-details
              @change="filtersCotizadorHotelsResult"
            >
              <template #label>
                <v-row>
                  <v-col cols="10">
                    <v-rating
                      background-color="default"
                      color="orange"
                      :empty-icon="icons.mdiStarOutline"
                      :full-icon="icons.mdiStar"
                      :half-icon="icons.mdiStarHalfFull"
                      length="5"
                      readonly
                      size="20"
                      dense
                      half-increments
                      :value="start2.cant_estrellas"
                    ></v-rating>
                  </v-col>
                  <v-col cols="2">
                    ({{ countStar(start2.id) }})
                  </v-col>
                </v-row>
              </template>
            </v-checkbox>
            <v-checkbox
              v-if="start1.id"
              v-model="cotizadorHotelsResultFilters.categories"
              :value="start1.id"
              hide-details
              @change="filtersCotizadorHotelsResult"
            >
              <template #label>
                <v-row>
                  <v-col cols="10">
                    <v-rating
                      background-color="default"
                      color="orange"
                      :empty-icon="icons.mdiStarOutline"
                      :full-icon="icons.mdiStar"
                      :half-icon="icons.mdiStarHalfFull"
                      length="5"
                      readonly
                      size="20"
                      dense
                      half-increments
                      :value="start1.cant_estrellas"
                    ></v-rating>
                  </v-col>
                  <v-col cols="2">
                    ({{ countStar(start1.id) }})
                  </v-col>
                </v-row>
              </template>
            </v-checkbox>
          </template>
          <v-skeleton-loader
            v-else
            class="mx-auto"
            type="card-heading"
          ></v-skeleton-loader>
        </div>

        <v-divider></v-divider>

        <!--PRECIO-->
        <div class="customizer-section">
          <span class="text--primary">{{ $t('lbl.priceFilters') }}</span><br />
          {{ cotizadorHotelsResultFilters.price[0] | currency }} - {{ cotizadorHotelsResultFilters.price[1] | currency }}
          <v-range-slider
            v-if="!loadingCategories"
            v-model="cotizadorHotelsResultFilters.price"
            :max="maxPrice"
            :min="minPrice"
            hide-details
            class="align-center"
            @change="filtersCotizadorHotelsResult"
          >
          </v-range-slider>
          <v-skeleton-loader
            v-else
            class="mx-auto"
            type="card-heading"
          ></v-skeleton-loader>
        </div>

        <v-divider></v-divider>

        <!--TIPO DESTINO-->
        <div class="customizer-section">
          <span class="text--primary">{{ $t('lbl.typeDestinyFilters') }}</span>
          <template v-if="!loadingTipoDestinos">
            <template
              v-for="(tipoDestino, indT) in tipoDestinos"
            >
              <v-checkbox
                v-if="indT < 3 || (indT >= 3 && !!showMoreTipoDestinos)"
                :key="indT"
                v-model="cotizadorHotelsResultFilters.tipoDestinos"
                :value="tipoDestino.id"
                hide-details
                @change="filtersCotizadorHotelsResult"
              >
                <template #label>
                  <v-row>
                    <v-col cols="10">
                      {{ tipoDestino.name }}
                    </v-col>
                    <v-col cols="2">
                      ({{ countTipoDestino(tipoDestino.id) }})
                    </v-col>
                  </v-row>
                </template>
              </v-checkbox>
            </template>
          </template>
          <v-skeleton-loader
            v-else
            class="mx-auto"
            type="card-heading"
          ></v-skeleton-loader>
          <v-row class="mt-3">
            <v-col cols="12">
              <span
                :style="`color: ${$vuetify.theme.themes.light.primary};text-decoration: none;cursor: pointer`"
                @click="showMoreTipoDestinos = !showMoreTipoDestinos"
              >{{ showMoreTipoDestinos ? $t('lbl.showLessFilters') : $t('lbl.showMoreFilters') }}</span>
            </v-col>
          </v-row>
        </div>

        <v-divider></v-divider>

        <!--CADENA HOTELERA-->
        <div class="customizer-section">
          <span class="text--primary">{{ $t('lbl.cadenaFilters') }}</span>
          <!--<v-select
            v-model="cotizadorHotelsResultFilters.cadenaHotelera"
            :items="operadores"
            :placeholder="$t('lbl.cadenaFilters')"
            outlined
            dense
            item-text="name"
            item-value="id"
            multiple
            @change="filtersCotizadorHotelsResult"
          >
          </v-select>-->
          <v-autocomplete
            v-if="!loadingOperadores"
            v-model="cotizadorHotelsResultFilters.cadenaHotelera"
            :items="cadenasItem"
            :search-input.sync="cadenaSearch"
            :label="$t('lbl.cadenaFilters')"
            outlined
            dense
            item-text="name"
            item-value="id"
            multiple
            @change="
              filtersCotizadorHotelsResult()
              cadenaSearch = null
              marcasItem = marcasDisponibles
            "
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('lbl.cadenaFilters') }}
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-autocomplete>
          <v-skeleton-loader
            v-else
            class="mx-auto"
            type="card-heading"
          ></v-skeleton-loader>
        </div>

        <v-divider></v-divider>

        <!--MARCA ASOCIADA-->
        <div class="customizer-section">
          <span class="text--primary">{{ $t('lbl.marcaFilters') }}</span>
          <v-autocomplete
            v-if="!loadingMarcas"
            v-model="cotizadorHotelsResultFilters.marcaHotelera"
            :items="marcasItem"
            :search-input.sync="marcaSearch"
            :label="$t('lbl.marcaFilters')"
            outlined
            dense
            item-text="name"
            item-value="id"
            multiple
            @change="
              filtersCotizadorHotelsResult()
              marcaSearch = null
            "
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('lbl.marcaFilters') }}
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-autocomplete>
          <v-skeleton-loader
            v-else
            class="mx-auto"
            type="card-heading"
          ></v-skeleton-loader>
        </div>

        <v-divider></v-divider>

        <!--TIPO DE RESERVA-->
        <div class="customizer-section">
          <span class="text--primary">{{ $t('lbl.typeReserveFilters') }}</span>
          <v-checkbox
            v-model="cotizadorHotelsResultFilters.tipoReserva"
            value="free-sale"
            hide-details
            @change="filtersCotizadorHotelsResult"
          >
            <template #label>
              <v-row>
                <v-col cols="10">
                  Free sale
                </v-col>
                <v-col cols="2">
                  ({{ countTipoReserva('free-sale') }})
                </v-col>
              </v-row>
            </template>
          </v-checkbox>
          <v-checkbox
            v-model="cotizadorHotelsResultFilters.tipoReserva"
            value="on-request"
            hide-details
            @change="filtersCotizadorHotelsResult"
          >
            <template #label>
              <v-row>
                <v-col cols="10">
                  On request
                </v-col>
                <v-col cols="2">
                  ({{ countTipoReserva('on-request') }})
                </v-col>
              </v-row>
            </template>
          </v-checkbox>
        </div>

        <v-divider></v-divider>

        <!--TIPO CONTRATO-->
        <template v-if="user.nivel <= 0">
          <div class="customizer-section">
            <span class="text--primary">{{ $t('lbl.contrateFilters') }}</span>
            <v-checkbox
              v-model="cotizadorHotelsResultFilters.contratos"
              value="directo"
              hide-details
              @change="filtersCotizadorHotelsResult"
            >
              <template #label>
                <v-row>
                  <v-col cols="10">
                    Directo
                  </v-col>
                  <v-col cols="2">
                    ({{ countContrato('directo') }})
                  </v-col>
                </v-row>
              </template>
            </v-checkbox>
            <v-checkbox
              v-model="cotizadorHotelsResultFilters.contratos"
              value="hotetec"
              hide-details
              @change="filtersCotizadorHotelsResult"
            >
              <template #label>
                <v-row>
                  <v-col cols="10">
                    Hotetec
                  </v-col>
                  <v-col cols="2">
                    ({{ countContrato('hotetec') }})
                  </v-col>
                </v-row>
              </template>
            </v-checkbox>
            <v-checkbox
              v-model="cotizadorHotelsResultFilters.contratos"
              value="dingus"
              hide-details
              @change="filtersCotizadorHotelsResult"
            >
              <template #label>
                <v-row>
                  <v-col cols="10">
                    Dingus
                  </v-col>
                  <v-col cols="2">
                    ({{ countContrato('dingus') }})
                  </v-col>
                </v-row>
              </template>
            </v-checkbox>
          </div>

          <v-divider></v-divider>
        </template>

        <!--SERVICIOS-->
        <div class="customizer-section">
          <span class="text--primary">{{ $t('lbl.servicesFilters') }}</span>
          <v-autocomplete
            v-if="!loadingServicios"
            v-model="cotizadorHotelsResultFilters.services"
            :items="servicesItem"
            :search-input.sync="serviceSearch"
            :label="$t('lbl.servicesFilters')"
            outlined
            dense
            item-text="name"
            item-value="id"
            multiple
            @change="
              filtersCotizadorHotelsResult()
              serviceSearch = null
            "
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('lbl.servicesFilters') }}
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-autocomplete>
          <v-skeleton-loader
            v-else
            class="mx-auto"
            type="card-heading"
          ></v-skeleton-loader>
          <!--<template
            v-for="(servicio, indS) in servicios"
          >
            <v-checkbox
              v-if="indS < 3 || (indS >= 3 && !!showMoreServicios)"
              :key="indS"
              v-model="cotizadorHotelsResultFilters.services"
              :value="servicio.id"
              hide-details
              @change="filtersCotizadorHotelsResult"
            >
              <template #label>
                <v-row>
                  <v-col cols="10">
                    {{ servicio.name }}
                  </v-col>
                  <v-col cols="2">
                    ({{ countServicio(servicio.id) }})
                  </v-col>
                </v-row>
              </template>
            </v-checkbox>
          </template>
          <v-row class="mt-3">
            <v-col cols="12">
              <span
                :style="`color: ${$vuetify.theme.themes.light.primary};text-decoration: none;cursor: pointer`"
                @click="showMoreServicios = !showMoreServicios"
              >{{ showMoreServicios ? $t('lbl.showLessFilters') : $t('lbl.showMoreFilters') }}</span>
            </v-col>
          </v-row>-->
        </div>

        <v-divider></v-divider>

        <!--INTERESES-->
        <div class="customizer-section">
          <span class="text--primary">{{ $t('lbl.interesesFilters') }}</span>
          <v-autocomplete
            v-if="!loadingIntereses"
            v-model="cotizadorHotelsResultFilters.intereses"
            :items="interesesItem"
            :search-input.sync="interesSearch"
            :label="$t('lbl.interesesFilters')"
            outlined
            dense
            item-text="name"
            item-value="id"
            multiple
            @change="
              filtersCotizadorHotelsResult()
              interesSearch = null
            "
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('lbl.interesesFilters') }}
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-autocomplete>
          <v-skeleton-loader
            v-else
            class="mx-auto"
            type="card-heading"
          ></v-skeleton-loader>
          <!--<template
            v-for="(interes, indIn) in intereses"
          >
            <v-checkbox
              v-if="indIn < 3 || (indIn >= 3 && !!showMoreIntereses)"
              :key="indIn"
              v-model="cotizadorHotelsResultFilters.intereses"
              :value="interes.id"
              hide-details
              @change="filtersCotizadorHotelsResult"
            >
              <template #label>
                <v-row>
                  <v-col cols="10">
                    {{ interes.name }}
                  </v-col>
                  <v-col cols="2">
                    ({{ countInteres(interes.id) }})
                  </v-col>
                </v-row>
              </template>
            </v-checkbox>
          </template>
          <v-row class="mt-3">
            <v-col cols="12">
              <span
                :style="`color: ${$vuetify.theme.themes.light.primary};text-decoration: none;cursor: pointer`"
                @click="showMoreIntereses = !showMoreIntereses"
              >{{ showMoreIntereses ? $t('lbl.showLessFilters') : $t('lbl.showMoreFilters') }}</span>
            </v-col>
          </v-row>-->

          <v-row>
            <v-col
              cols="12"
              md="9"
              offset-md="2"
              class="mt-10"
            >
              <v-btn
                color="primary"
                outlined
                block
                @click="clearCotizadorHotelsResultFilters"
              >
                {{ $t('lbl.clearFilters') }}
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </perfect-scrollbar>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { ref } from '@vue/composition-api'
// eslint-disable-next-line import/no-unresolved
import useAppConfig from '@core/@app-config/useAppConfig'

import {
  mdiFilterMenu, mdiClose, mdiStarOutline, mdiStar, mdiStarHalfFull, mdiMagnify,
} from '@mdi/js'

import { PerfectScrollbar } from 'vue2-perfect-scrollbar'

// 3rd Party

export default {
  components: {
    PerfectScrollbar,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    user: {
      type: Object,
    },
  },
  setup() {
    const isCustomizerOpen = ref(false)
    // eslint-disable-next-line object-curly-newline
    const {
      isDark,
      isRtl,
      themes,
    } = useAppConfig()

    const perfectScrollbarOptions = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    return {
      isCustomizerOpen,

      isDark,
      isRtl,
      themes,

      // Perfect scrollbar options
      perfectScrollbarOptions,
    }
  },
  data() {
    return {
      categories: [],
      loadingCategories: true,
      tipoDestinos: [],
      loadingTipoDestinos: true,
      intereses: [],
      loadingIntereses: true,
      servicios: [],
      loadingServicios: true,
      operadores: [],
      loadingOperadores: true,
      marcas: [],
      loadingMarcas: true,
      icons: {
        mdiFilterMenu,
        mdiClose,
        mdiStarOutline,
        mdiStar,
        mdiStarHalfFull,
        mdiMagnify,
      },
      start5: {
        id: null,
        cant_estrellas: null,
        name: null,
      },
      start4: {
        id: null,
        cant_estrellas: null,
        name: null,
      },
      start3: {
        id: null,
        cant_estrellas: null,
        name: null,
      },
      start2: {
        id: null,
        cant_estrellas: null,
        name: null,
      },
      start1: {
        id: null,
        cant_estrellas: null,
        name: null,
      },
      minPrice: 0,
      maxPrice: 1000,
      showMoreTipoDestinos: false,
      showMoreServicios: false,
      showMoreIntereses: false,
      permisos: sessionStorage.getItem('permisos_auth'),
      cadenaSearch: null,
      cadenasItem: [],
      marcaSearch: null,
      marcasItem: [],
      serviceSearch: null,
      servicesItem: [],
      interesSearch: null,
      interesesItem: [],
    }
  },
  computed: {
    ...mapState({
      cotizadorHotelsAll: state => state.app.cotizadorHotelsAll,
      cotizadorHotelsResult: state => state.app.cotizadorHotelsResult,
      cotizadorHotelsResultPaginate: state => state.app.cotizadorHotelsResultPaginate,
      cotizadorHotelsResultFilters: state => state.app.cotizadorHotelsResultFilters,
    }),
    countFilters() {
      let cont = 0
      if (this.cotizadorHotelsResultFilters.name !== null) {
        cont += 1
      }
      if (this.cotizadorHotelsResultFilters.categories.length > 0) {
        cont += 1
      }
      if (this.cotizadorHotelsResultFilters.tipoDestinos.length > 0) {
        cont += 1
      }
      if (this.cotizadorHotelsResultFilters.cadenaHotelera.length > 0) {
        cont += 1
      }
      if (this.cotizadorHotelsResultFilters.marcaHotelera.length > 0) {
        cont += 1
      }
      if (this.cotizadorHotelsResultFilters.tipoReserva.length > 0) {
        cont += 1
      }
      if (this.cotizadorHotelsResultFilters.contratos.length > 0) {
        cont += 1
      }
      if (this.cotizadorHotelsResultFilters.services.length > 0) {
        cont += 1
      }
      if (this.cotizadorHotelsResultFilters.intereses.length > 0) {
        cont += 1
      }
      if (this.cotizadorHotelsResultFilters.orderByRecomendado !== null) {
        cont += 1
      }
      if (this.cotizadorHotelsResultFilters.orderByCategory !== null) {
        cont += 1
      }
      if (this.cotizadorHotelsResultFilters.orderByPrice !== 'asc') {
        cont += 1
      }

      return cont
    },
    countStar() {
      return star => {
        let cont = 0

        this.cotizadorHotelsResultPaginate.forEach(element => {
          if (element.hotel.categoria.id === parseInt(star, 10)) {
            cont += 1
          }
        })

        return cont
      }
    },
    countTipoDestino() {
      return item => {
        let cont = 0

        this.cotizadorHotelsResultPaginate.forEach(element => {
          if (element.hotel.tipo_destino.id === parseInt(item, 10)) {
            cont += 1
          }
        })

        return cont
      }
    },
    countTipoReserva() {
      return item => {
        let cont = 0

        this.cotizadorHotelsResultPaginate.forEach(element => {
          if (element.tipo_reserva === item) {
            cont += 1
          }
        })

        return cont
      }
    },
    countContrato() {
      return item => {
        let cont = 0

        this.cotizadorHotelsResultPaginate.forEach(element => {
          if (item === 'directo') {
            if (element.sources.length === 0) {
              cont += 1
            }
          } else if (element.sources.includes(item)) {
            cont += 1
          }
        })

        return cont
      }
    },
    countServicio() {
      return item => {
        let cont = 0

        this.cotizadorHotelsResultPaginate.forEach(element => {
          if (element.hotel.servicios_id.includes(item)) {
            cont += 1
          }
        })

        return cont
      }
    },
    countInteres() {
      return item => {
        let cont = 0

        this.cotizadorHotelsResultPaginate.forEach(element => {
          if (element.hotel.intereses_id.includes(item)) {
            cont += 1
          }
        })

        return cont
      }
    },
    marcasDisponibles() {
      let arr = []
      if (this.cotizadorHotelsResultFilters.cadenaHotelera.length === 0) {
        arr = this.marcas
      } else {
        this.marcas.forEach(element => {
          // eslint-disable-next-line no-plusplus
          for (let index = 0; index < element.operador_id.length; index++) {
            const opera = element.operador_id[index]
            if (this.cotizadorHotelsResultFilters.cadenaHotelera.includes(opera)) {
              arr.push(element)
              break
            }
          }
        })
      }

      return arr
    },
  },
  watch: {
    cadenaSearch(val) {
      if (val.length > 0) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterCadena(val.toLowerCase())
      } else {
        this.cadenasItem = []
      }
    },
    marcaSearch(val) {
      if (val.length > 0) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterMarca(val.toLowerCase())
      } else {
        this.marcasItem = []
      }
    },
    serviceSearch(val) {
      if (val.length > 0) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterService(val.toLowerCase())
      } else {
        this.servicesItem = []
      }
    },
    interesSearch(val) {
      if (val.length > 0) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterInteres(val.toLowerCase())
      } else {
        this.interesesItem = []
      }
    },
  },
  created() {
    this.getCategorias()
    this.getTipoDestino()
    this.getIntereses()
    this.getServicios()
    this.getOperadores()
    this.getMarcas()

    this.modifyRangePrice()
  },
  methods: {
    ...mapMutations([
      'filtersCotizadorHotelsResult',
      'clearCotizadorHotelsResultFilters',
    ]),
    filterCadena(v) {
      this.cadenasItem = []
      if (v === '') {
        this.cadenasItem = []
      } else {
        this.cadenasItem = this.operadores.filter(e => e.name.toLowerCase())
      }
    },
    filterMarca(v) {
      this.marcasItem = []
      if (v === '') {
        this.marcasItem = []
      } else {
        this.marcasItem = this.marcasDisponibles.filter(e => e.name.toLowerCase())
      }
    },
    filterService(v) {
      this.servicesItem = []
      if (v === '') {
        this.servicesItem = []
      } else {
        this.servicesItem = this.servicios.filter(e => e.name.toLowerCase())
      }
    },
    filterInteres(v) {
      this.interesesItem = []
      if (v === '') {
        this.interesesItem = []
      } else {
        this.interesesItem = this.intereses.filter(e => e.name.toLowerCase())
      }
    },
    modifyRangePrice() {
      if (this.cotizadorHotelsResult.length > 0) {
        this.cotizadorHotelsResultFilters.price[0] = this.cotizadorHotelsResult[0].minPriceMark
        this.maxPrice = this.cotizadorHotelsResult[this.cotizadorHotelsResult.length - 1].minPriceMark + 50
        this.cotizadorHotelsResultFilters.price[1] = this.cotizadorHotelsResult[this.cotizadorHotelsResult.length - 1].minPriceMark
      }
    },

    getCategorias() {
      this.axios
        .get(`nom_categorias_hotels?per_page=1000&perfil_slug=${sessionStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.categories = res.data.data

          this.categories.forEach(element => {
            if (element.cant_estrellas === 5) {
              this.start5 = element
            } else if (element.cant_estrellas === 4) {
              this.start4 = element
            } else if (element.cant_estrellas === 3) {
              this.start3 = element
            } else if (element.cant_estrellas === 2) {
              this.start2 = element
            } else if (element.cant_estrellas === 1) {
              this.start1 = element
            }
          })
        })
        .finally(() => {
          this.loadingCategories = false
        })
    },
    getTipoDestino() {
      this.axios
        .get(`nom_tipo_destino_hotels?per_page=1000&perfil_slug=${sessionStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.tipoDestinos.push(res.data.data.filter(e => e.slug === 'ciudad')[0])
          this.tipoDestinos.push(res.data.data.filter(e => e.slug === 'naturaleza')[0])
          this.tipoDestinos.push(res.data.data.filter(e => e.slug === 'sol-y-playa')[0])
          this.tipoDestinos.push(res.data.data.filter(e => e.slug === 'cayos')[0])
        })
        .finally(() => {
          this.loadingTipoDestinos = false
        })
    },
    getIntereses() {
      this.axios
        .get(`nom_intereses_hotels?per_page=1000&perfil_slug=${sessionStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.intereses = res.data.data

          this.interesesItem = this.intereses
        })
        .finally(() => {
          this.loadingIntereses = false
        })
    },
    getServicios() {
      this.axios
        .get(`nom_servicios_hotels?per_page=1000&perfil_slug=${sessionStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.servicios = res.data.data

          this.servicesItem = this.servicios
        })
        .finally(() => {
          this.loadingServicios = false
        })
    },
    getOperadores() {
      this.axios
        .get(`nom_operador_hotels?per_page=1000&perfil_slug=${sessionStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.operadores = res.data.data

          this.cadenasItem = this.operadores
        })
        .finally(() => {
          this.loadingOperadores = false
        })
    },
    getMarcas() {
      this.axios
        .get(`nom_marca_asociada_hotels?per_page=1000&perfil_slug=${sessionStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.marcas = res.data.data

          this.marcasItem = this.marcasDisponibles
        })
        .finally(() => {
          this.loadingMarcas = false
        })
    },
  },
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';

.ps-customizer {
  height: calc(100% - 81px) !important;
}

.app-customizer-toggler {
  position: fixed;
  top: 50%;
  transform: translateX(-50%);
  background: var(--v-primary-base);
  @include ltr() {
    right: -22px;
  }
  @include rtl() {
    left: 20px;
  }
}

@include theme(app-customizer-drawer) using ($material) {
  background-color: map-deep-get($material, 'cards');
}

.app-customizer {
  z-index: 7;

  .v-label {
    font-size: 0.875rem;
  }

  .app-customizer-header {
    position: relative;
    .icon-customizer-close {
      position: absolute;
      @include ltr() {
        right: 20px;
      }
      @include rtl() {
        left: 20px;
      }
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .customizer-section {
    padding: 24px;
  }

  // Fixes Overlay is shown below SystemBar
  @at-root {
    .v-overlay {
      & + .v-application--wrap > .v-system-bar {
        z-index: 6 !important;
      }
    }
  }
}
</style>
